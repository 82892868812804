

.footer{
    background-color: #FFF;
    padding: 3rem 1rem;
    @media (min-width: 640px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .footer-holder {
        max-width: 80rem;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        row-gap: 30px;
        flex-direction: column;
        a {
            text-decoration: none;
            color: #4D4D4D;
        }
        .logoholder {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .logo {
                display: flex;
                align-items: center;
                img {
                    height: 2.5rem;
                    display: block;
                }
                .sitename {
                    display: none;
                    margin: 0 0.5rem;
                    text-align: left;
                    display: block;
                    color: #274066;
                    margin-top: 1rem;
                    h1 {
                        font-size: 1.5rem;
                        margin-bottom: 0.25rem !important;
                    }
                    h2 {
                        font-weight: 600;
                        letter-spacing: .1em;
                        text-transform: uppercase;
                        font-size: .75rem;
                    }
                }
            }
        }
        .footer-horizontal-divider{
            height: 2px;
            width: 100%;
            background-color: #E7E7E7;
        }
        .footer-vertical-divider{
            height: 19px;
            width: 2px;
            background-color: #E7E7E7;
        }
        .font-13 {
            text-align: left;
        }
        .footer-links{
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 24px;
            .div {
                a {
                    text-decoration: none;
                    color: #4D4D4D;
                }
            }
        }
    }
}