
.form-start{
    width: 100%;
    height: 100%;
}

.pre-form{
    max-width: 100%;
    height: 100%;
    background-color: #F5FBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../assets/mainback.png');
    background-position: initial;
    .pre-form-holder {
        max-width: 750px;
        margin: 0 auto;
        padding: 50px 10px;
        font-size: 18px;
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .pre-form-container {
            .headline {
                font-weight: 600;
                font-size: 1.5rem;
                margin: 1rem 0 0 0;
                @media (min-width: 768px) {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 36px;
                }
            }
            .sub-headline {
                font-size: 20px;
                font-weight: 500;
                line-height: 25px;
                margin: 1rem 0;
                span {
                    font-weight: bold;
                }
                @media (min-width: 768px) {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 36px;
                }
            }
            .paragraph {
                display: block;
            }
            .qualify-button{
                margin: 1rem 0;
                padding: 15px 40px;
                color: white;
                font-weight: 700;
                font-size: 24px;
                background-color: #00B67A;
                border-radius: 40px;
                cursor: pointer;
                transition: 0.3s;
                display: block;
                @media (min-width: 768px) {
                    display: inline-block;
                }
                &:hover{
                    transform: scale(1.04);
                }
                &:active{
                    transform: scale(0.99);
                }
            }
        }
        .carrier-images {
            max-width: 620px;
            margin: 1rem auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            img {
                height: 50px;
            }
        }
    }
}

.rating{
    margin: 1rem 0;
    //padding: 4.375rem 10%;
    row-gap: 40px;
    .rating-head{
        row-gap: 10px;
        padding: 2.5rem 1.5rem 0 1.5rem;
        text-align: center;
        .rating-headline {
            color: #274066;
            font-size: 1.5rem;
            font-weight: 500;
            @media (min-width: 768px) {
                font-size: 2rem;
            }
        }
    }
    .rating-card-holder{
        width: 100%;
        z-index: 2 !important;
        max-width: 1010px;
        margin: 1rem 0;
    }
    .review-head{
        padding: 1.5rem;
        max-width: 350px;
        justify-content: center;
        align-items: center;
        text-align: center;
        //column-gap: 20px;
        //flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        flex-wrap: wrap;
        .first {
            flex: 0 25%;
            margin-right: .5rem;
        }
        .second {
            flex: 0 25%;
        }
        .third {
            flex: 0 100%;
        }
        .fourth {
            flex: 0 100%;
        }
        @media (min-width:768px) {
            max-width: 700px;
            .first {
                flex: auto;
            }
            .second {
                flex: auto;
                margin-right: .5rem;
            }
            .third {
                flex: auto;
                margin-right: .5rem;
            }
            .fourth{
                flex: auto;
            }
        }
    }
    
}
.CarouselItem-module_darkened__YQrzB{
    background-color: rgba(255, 255, 255, 0.425) !important;
    border-radius: 7px;
}

.Carousel-module_itemContainer__yTu7G {
    &.Carousel-module_threed__xcY2m {
        width: 100% !important;
        @media (min-width:576px) {
            width: 53% !important;
        }
    }
}

.rating-card{
    max-width: 31rem;
    min-height: 18rem;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    background-color: white;
    gap: 20px;
    transform: scale(0.93);
}
